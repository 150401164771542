import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    Grid,
    Header,
    Message,
} from "semantic-ui-react";

import { RootState } from "../../redux/store";
import VariantList from "./VariantList";


/**
 * Represents the variants page, where user can configure their variants for each design.
 *
 * @returns The variants page
 */
export default function Variants (): ReactElement {
    const order = useSelector((state: RootState) => state.order);
    const designs = useSelector((state: RootState) => state.designs);

    const [ t ] = useTranslation();


    return (
        <>
            <Header as="h1">{t("variants:header")}</Header>

            <Message
                error
                visible={order.error !== null}
                hidden={order.error === null}
            >
                {order.error}
            </Message>

            <Grid
                stackable
                divided="vertically"
                doubling
                columns={2}
            >
                {Object.keys(designs).map((fileAlias) => {
                    const design = designs[fileAlias];

                    if (!design) {
                        return (<></>);
                    }

                    return (
                        <VariantList key={design.fileAlias}
                            fileAlias={design.fileAlias}/>
                    );
                })}
            </Grid>
        </>
    );
}
