import { AxiosError } from "axios";
import { t } from "i18next";

import config from "../config/config";
import { formatFileSize } from "../utils/file";
import { ServiceError } from "./types";

export class DesignErrorHandler {
    static initUpload (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 422:
            message = t("messages:designs.initUpload.invalidFileName");
            break;
        case 413:
            message = t("messages:designs.initUpload.fileTooLarge", { maxSize: formatFileSize(config.files.maxSize) });
            break;
        case 415:
            message = t("messages:designs.initUpload.invalidFileFormat", { validFormats: config.files.extensions.join(", ") });
            break;
        default:
            message = t("messages:designs.initUpload.uploadError");
            break;
        }

        return { statusCode, message };
    }

    static completeUpload (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        default:
            message = t("messages:designs.initUpload.uploadError");
            break;
        }

        return { statusCode, message };
    }

    static getPartUploadPresignedUrls (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        const message = t("messages:designs.initUpload.uploadError");

        return { statusCode, message };
    }

    static uploadPart (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        default:
            message = t("messages:designs.initUpload.uploadError");
            break;
        }

        return { statusCode, message };

    }

    static getPublicUrl (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        default:
            message = t("messages:designs.publicUrl.error");
            break;
        }

        return { statusCode, message };
    }

    static getDimensions (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 422:
            message = t("messages:designs.invalid");
            break;
        default:
            message = t("messages:designs.dimensions.loadingError");
            break;
        }

        return { statusCode, message };
    }

    static getCompatiblePrintOptions (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        default:
            message = t("messages:designs.printOptions.noCompatibleOptions");
            break;
        }

        return { statusCode, message };
    }

    static getAnalysis (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        default:
            message = t("messages:designs.analysis.loadingError");
            break;
        }

        return { statusCode, message };
    }
}
