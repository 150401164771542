import React, { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Header } from "semantic-ui-react";

import PageTemplate from "../../templates/PageTemplate";

export default function ShippingConditions (): ReactElement {
    const [ t ] = useTranslation();

    return <PageTemplate>
        <Container
            text
            style={{
                marginTop: "60px",
                paddingBottom: "60px",
            }}
        >
            <Header as="h1">{t("shippingConditions:title")}</Header>
            <p>{t("shippingConditions:paragraph0")}</p>

            <Header as="h2">{t("shippingConditions:germany.title")}</Header>
            <p>{t("shippingConditions:germany.countries")}</p>
            <p>
                {t("shippingConditions:germany.paragraph")}
                <br/>
                {t("shippingConditions:germany.shippingTime")}
            </p>

            <Header as="h2">{t("shippingConditions:EU.title")}</Header>
            <p>{t("shippingConditions:EU.countries")}</p>
            <p>
                {t("shippingConditions:EU.paragraph")}
                <br/>
                {t("shippingConditions:EU.shippingTime")}
            </p>

            <Header as="h3">{t("shippingConditions:austria.title")}</Header>
            <p>{t("shippingConditions:austria.paragraph")}</p>



            <Header as="h2">{t("shippingConditions:abroad.title")}</Header>
            <p>{t("shippingConditions:abroad.countries")}</p>
            <p>
                {t("shippingConditions:abroad.paragraph")}
                <br/>
                {t("shippingConditions:abroad.shippingTime")}
            </p>

            <Header as="h3">{t("shippingConditions:switzerland.title")}</Header>
            <p>{t("shippingConditions:switzerland.paragraph")}</p>
            <p>
                <Trans i18nKey="shippingConditions:switzerland.note">
                    <strong>Note:</strong> Customs fees may apply.
                </Trans>
            </p>
        </Container>
    </PageTemplate>;
}
