import { AxiosError } from "axios";
import { t } from "i18next";

import { ServiceError } from "./types";

export class OrderErrorHandler {
    static init (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 429:
            message = t("messages:order.init.capacityLimitReached");
            break;
        default:
            message = t("messages:order.init.error");
            break;
        }

        return { statusCode, message };
    }

    static get (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 404:
            message = t("messages:order.get.notFound");
            break;
        case 410:
            message = t("messages:order.get.expired");
            break;
        default:
            message = t("messages:order.get.error");
            break;
        }

        return { statusCode, message };
    }

    static getDesigns (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 404:
            message = t("messages:order.get.notFound");
            break;
        default:
            message = t("messages:order.get.error");
            break;
        }

        return { statusCode, message };
    }

    static getVariants (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 404:
            message = t("messages:order.get.notFound");
            break;
        default:
            message = t("messages:order.get.error");
            break;
        }

        return { statusCode, message };
    }

    static cancel (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 404:
            message = t("messages:order.get.notFound");
            break;
        default:
            message = t("messages:order.cancellationFailed");
            break;
        }

        return { statusCode, message };
    }

    static checkout (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 400:
            message = t("messages:order.checkout.notReady");
            break;
        case 409:
            message = t("messages:order.checkout.notReady");
            break;
        case 404:
            message = t("messages:order.get.notFound");
            break;
        default:
            message = t("messages:order.checkout.error");
            break;
        }

        return { statusCode, message };
    }

    static reopen (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 404:
            message = t("messages:order.get.notFound");
            break;
        default:
            message = t("messages:order.reopenFailed");
            break;
        }

        return { statusCode, message };
    }
}
