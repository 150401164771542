import React, { ReactElement } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    Dropdown,
    Flag,
    Header,
    Icon,
    Image, Menu,
} from "semantic-ui-react";

import { resolveLanguage } from "../i18n/i18n";
import { orderStore } from "../redux/slices/order";
import { RootState } from "../redux/store";
import DesignPageContinueButton from "./side-menu/DesignPageContinueButton";
import VariantPageBackButton from "./side-menu/VariantPageBackButton";
import VariantPageCheckoutButton from "./side-menu/VariantPageCheckoutButton";
import UserGuide from "./user-guide/UserGuide";

/**
 * @returns The navbar component
 */
export default function Navbar (): ReactElement {
    const order = useSelector((state: RootState) => state.order);
    const dispatch = useDispatch();

    const [ t, i18n ] = useTranslation();

    function changeLanguage (lang: string): void {
        lang = resolveLanguage(undefined, lang);
        i18n.changeLanguage(lang);
    }

    return (
        <>
            <Menu
                fixed="top"
                inverted
                borderless
                fluid
            >
                <Menu.Item
                    position="left"
                    fitted
                    style={{ padding: "15px" }}
                    header
                >
                    <Header as="a"
                        href="https://knopfdruck.eu/"
                        target="_blank"
                        style={{
                            fontSize: "2.5em",
                            color: "white",
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Image
                                src="/img/brand-logo.png"
                                as="a"
                                href="https://knopfdruck.eu/"
                                target="_blank"
                                style={{
                                    width: "60px",
                                    ...!isMobile ? { marginRight: "20px" } : {  },
                                }}
                            />
                            {
                                isMobile ?
                                    <></>
                                    : <><span style={{ color: "#46bfc3" }}>k</span>nopfdruck</>
                            }
                        </div>
                    </Header>
                </Menu.Item>


                {order.step === "DESIGNS" ? (
                    <Menu.Item position="right">
                        <DesignPageContinueButton/>
                    </Menu.Item>) : (<></>)}
                {order.step === "VARIANTS" ? (
                    <Menu.Item
                        position="right"
                    >
                        <VariantPageBackButton/>
                        <VariantPageCheckoutButton/>
                    </Menu.Item>
                ) : (<></>)}

                <Dropdown
                    simple
                    item
                    icon="bars"
                    direction="left"
                >
                    <Dropdown.Menu>
                        {
                            order?.id ? (
                                <>
                                    <Dropdown.Item
                                        link
                                        onClick={(): void => {dispatch(orderStore.toggleUserGuide());}}
                                    >
                                        <Icon
                                            name="help circle"
                                            size="large"
                                        />
                                        <span>{t("navbar:menu.help")}</span>
                                    </Dropdown.Item>
                                    <Dropdown.Divider/>
                                </>
                            ) : <></>
                        }
                        <Dropdown.Header>{t("navbar:menu.language.title")}</Dropdown.Header>
                        <Dropdown.Item
                            icon={<Flag name="de"/>}
                            text={t("navbar:menu.language.options.de")}
                            key="de"
                            value="de"
                            selected={i18n.language === "de"}
                            onClick={(): void => {changeLanguage("de");}}
                        />
                        <Dropdown.Item
                            icon={<Flag name="us"/>}
                            text={t("navbar:menu.language.options.en")}
                            key="en"
                            value="en"
                            selected={i18n.language === "en"}
                            onClick={(): void => {changeLanguage("en");}}
                        />
                    </Dropdown.Menu>
                </Dropdown>

            </Menu>

            {
                order?.id ? <UserGuide/> : <></>
            }

            <div style={{ height: "60px" }}/>
        </>
    );
}
