import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";

import { isServiceError } from "../../errors/types";
import { designStore } from "../../redux/slices/design";
import { orderStore } from "../../redux/slices/order";
import { variantStore } from "../../redux/slices/variant";
import { RootState } from "../../redux/store";
import { OrderService } from "../../service/order";
import { designIsProcessed, designIsReady } from "../../states/design";
import { variantIsValid } from "../../states/variant";

export default function VariantPageCheckoutButton () : ReactElement {
    const dispatch = useDispatch();

    const order = useSelector((state: RootState) => state.order);

    const designs = useSelector((state: RootState) => state.designs);
    const variants = useSelector((state: RootState) => state.variants);


    /**
     * Enables the checkout button if the following conditions are met:
     * - All designs are ready
     * - All designs are valid
     * - Every design has at least one variant
     * - Every variant is valid
     * - Every variant has a price
     *
     * @returns Whether or not the checkout button should be enabled.
     */
    function enableCheckoutButton (): boolean {
        if (order.checkoutInProgress) {
            return false;
        }

        if (Object.keys(designs).length === 0) {
            return false;
        }

        for (const design of Object.values(designs)) {
            if (!designIsReady(design)) {
                return false;
            }

            if (designIsProcessed(design)) {
                return false;
            }

            if (design.error) {
                return false;
            }

            let hasValidVariant = false;

            for (const variant of Object.values(variants).filter((variant) => variant?.designId === design.id)) {
                if (variantIsValid(variant)) {
                    hasValidVariant = true;
                } else {
                    return false;
                }
            }

            if (!hasValidVariant) {
                return false;
            }
        }

        return true;
    }

    function clearState (): void {
        dispatch(orderStore.clear());
        dispatch(designStore.clear());
        dispatch(variantStore.clear());
    }

    /**
     * Handles the checkout event.
     * If checkout request was succesful, the user will be redirected to the checkout url.
     * Otherwise the `order.error` will be set.
     */
    async function checkout (): Promise<void> {
        if (!order.id) {
            return;
        }

        dispatch(orderStore.setCheckoutInProgress(true));

        const checkoutUrlResponse = await OrderService.checkout(order.id);

        if (!isServiceError(checkoutUrlResponse)) {
            const checkoutUrl = checkoutUrlResponse as string;
            clearState();

            dispatch(orderStore.setStep("CHECKOUT"));
            dispatch(orderStore.setCheckoutInProgress(false));

            window.location.href = checkoutUrl;

            return;
        }

        dispatch(orderStore.setError(checkoutUrlResponse.message));
        dispatch(orderStore.setCheckoutInProgress(false));
    }
    return  <Button
        color="teal"
        loading={order.checkoutInProgress}
        disabled={!enableCheckoutButton()}
        onClick={checkout}
    >
        Checkout
    </Button>;
}
