import React, {
    ReactElement, useEffect, useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    Button, DropdownItemProps, DropdownProps, Form,
    Grid,
    Header,
    Icon,
    InputOnChangeData,
    List,
    Message, Modal,
    Popup, Segment, TextArea,
    TextAreaProps,
} from "semantic-ui-react";

import config, {
    ColorId, Material, Procedure,
} from "../../../config/config";
import { isServiceError } from "../../../errors/types";
import { designStore } from "../../../redux/slices/design";
import { variantStore } from "../../../redux/slices/variant";
import { RootState } from "../../../redux/store";
import { VariantService } from "../../../service/variant";
import { CompatiblePrintOptions } from "../../../states/print";
import { variantIsValid } from "../../../states/variant";
import Price from "../Price";
import MaterialProsAndCons from "./MaterialProsAndCons";


/**
 * @param variantId - The identifier of the variant to edit.
 * @returns Returns a modal to edit the specifications of a variant.
 */
export default function SpecificationsEdit (props: { variantId :string }): ReactElement {
    const dispatch = useDispatch();

    const variant = useSelector((state: RootState) => state.variants[props.variantId]);
    const design = useSelector((state: RootState) => {
        const variant = state.variants[props.variantId];

        if (!variant) {
            return undefined;
        }

        for (const designKey in state.designs) {
            if (state.designs[designKey].id === variant.designId) {
                return state.designs[designKey];
            }
        }
    });

    const [ t ] = useTranslation();

    if (!design) {
        return (<></>);
    }

    if (!design.compatiblePrintOptions) {
        return (<></>);
    }

    if (!variant) {
        return (<></>);
    }


    const [ procedure, setProcedure ] = useState<Procedure>(variant.printOptions.procedure as Procedure);
    const [ colorId, setColorId ] = useState<string>(variant.printOptions.colorId);
    const [ material, setMaterial ] = useState<Material>(variant.printOptions.material as Material);
    const [ infill, setInfill ] = useState<number>(variant.printOptions.infill);

    const [ quantity, setQuantity ] = useState<number | null>(variant.quantity);
    const [ customerNote, setCustomerNote ] = useState<string>("");

    const [ specsChanged, setSpecsChanged ] = useState<boolean>(false);
    const [ saveInProgress, setSaveInProgress ] = useState<boolean>(false);
    const [ cancelInProgress, setCancelInProgress ] = useState<boolean>(false);


    /**
     * Initializes the state variables for the edit modal. They are set to the current values of the variant in
     * the redux store.
     */
    function startEdit (): void {
        setProcedure(variant.printOptions.procedure as Procedure);
        setMaterial(variant.printOptions.material as Material);
        setColorId(variant.printOptions.colorId);
        setInfill(variant.printOptions.infill);

        setQuantity(variant.quantity);
        setCustomerNote(variant.customerNote ?? "");
    }

    /**
     * Handles the process to save the edited variant.
     */
    async function saveEdit (): Promise<boolean> {
        if (!design) {
            return false;
        }

        if (quantity === null) {
            return false;
        }
        setSpecsChanged(false);

        setSaveInProgress(true);
        dispatch(designStore.setIsSlicing({ fileAlias: design.fileAlias, isSlicing: true }));
        dispatch(variantStore.setPrice({ variantId: variant.id, pricePerPiece: undefined }));

        const response = await VariantService.updateSpecifications(
            variant.id,
            {
                procedure,
                material,
                colorId,
                infill,
            },
            quantity,
            customerNote,
        );

        if (isServiceError(response)) {
            dispatch(variantStore.setError({ variantId: variant.id, error: response.message }));
        } else {
            dispatch(variantStore.setSpecifications({ variantId: variant.id, quantity, customerNote, printOptions: { procedure, material, colorId: colorId, infill } }));
            dispatch(variantStore.clearError(variant.id));
        }

        setSaveInProgress(false);
        dispatch(designStore.setIsSlicing({ fileAlias: design.fileAlias, isSlicing: false }));

        return !isServiceError(response);
    }

    async function saveEditAndClose (): Promise<void> {
        const success = await saveEdit();

        if (success) {
            dispatch(variantStore.toggleEditMode(variant.id));
        }
    }

    /**
     * Handles the process to cancel the edit of the variant. If the variant is currently in an error state,
     * the previous state is tried to be restored from the redux store. If successful, the error is cleared.
     *
     * If the variant is not in an error state, the edit mode is simply toggled off.
     */
    async function cancelEdit (): Promise<void> {
        setCancelInProgress(true);

        if (!variantIsValid(variant) && !design?.isSlicing) {
            const response = await VariantService.updateSpecifications(
                variant.id,
                {
                    procedure: variant.printOptions.procedure as Procedure,
                    material: variant.printOptions.material as Material,
                    colorId: variant.printOptions.colorId,
                    infill: variant.printOptions.infill,
                },
                variant.quantity,
                variant.customerNote,
            );

            if (isServiceError(response)) {
                dispatch(variantStore.setError({ variantId: variant.id, error: response.message }));
            }
            // else {
            // dispatch(variantStore.clearError(variant.id));
            // }
        }

        dispatch(variantStore.toggleEditMode(variant.id));
        setCancelInProgress(false);
    }

    function allowCancel (): boolean {
        return !cancelInProgress && !saveInProgress;
    }

    /**
     * Handles the change event of a procedure. If the procedure changes, the state variable for the `procedure`
     * is updated and the state variables for `material` and `color` are updated to the first compatible option.
     *
     * @param event -
     * @param data -
     */
    function changeProcedure (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void {
        if (!design?.compatiblePrintOptions) {
            return;
        }

        const procedureExists = data.value as Procedure in design.compatiblePrintOptions;

        if (!procedureExists) {
            return;
        }

        setProcedure(data.value as Procedure);

        const materialOptions = getMaterialDropdownOptions(data.value as Procedure, design.compatiblePrintOptions);
        const Material = materialOptions[0].value as Material;
        setMaterial(Material);

        const colorOptions = getColorDropdownOptions(data.value as Procedure, Material, design.compatiblePrintOptions);
        const colorName = colorOptions[0].value as string;
        setColorId(colorName);

        setSpecsChanged(true);
    }

    /**
     * Handles the change event of a material. If the material changes, the state variable for the `material`
     * is updated and the state variable for `color` is updated to the first compatible option.
     *
     * @param event -
     * @param data -
     */
    function changeMaterial (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void {
        if (!design?.compatiblePrintOptions) {
            return;
        }

        const materialExists = design?.compatiblePrintOptions?.[procedure]?.[data.value as Material];

        if (!materialExists) {
            return;
        }

        setMaterial(data.value as Material);

        const colorOptions = getColorDropdownOptions(procedure, data.value as Material, design.compatiblePrintOptions);
        const colorName = colorOptions[0].value as string;
        setColorId(colorName);

        setSpecsChanged(true);
    }

    /**
     * Handles the change event of a color. If the color changes, the state variable for the `color` is updated.
     *
     * @param event -
     * @param data -
     */
    function changeColor (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void {
        const colorExists = design?.compatiblePrintOptions?.[procedure]?.[material]?.colors.find(
            (colorId: ColorId) => colorId === data.value,
        );

        if (!colorExists) {
            return;
        }

        setColorId(data.value as string);
        setSpecsChanged(true);
    }

    /**
     * Handle the change event of the quantity. THe following checks are performed:
     * - The new quantity is a number
     * - The new quantity is between 1 and 10
     * If all checks are succesfull the state variable for the `quantity` is updated.
     *
     * @param event -
     * @param data -
     */
    function changeQuantity (event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData): void {
        const newQuantity = parseInt(data.value as string);

        if (isNaN(newQuantity)) {
            setQuantity(null);
            return;
        }

        if (newQuantity < 1) {
            setQuantity(1);
            return;
        }

        if (newQuantity > config.variants.maxQuantity) {
            setQuantity(config.variants.maxQuantity);
            return;
        }

        setQuantity(newQuantity);
        setSpecsChanged(true);
    }

    /**
     * Handles the change event of the infill. The following checks are performed:
     * - The new infill is a number
     * - The new infill is between 10 and 100
     * - The new infill is a multiple of 10
     * If all checks are succesfull the state variable for the `infill` is updated.
     *
     * @param event -
     * @param data -
     */
    function changeInfill (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void {
        const newInfill = parseInt(data.value as string);

        if (isNaN(newInfill)) {
            return;
        }

        if (newInfill < 10 || newInfill > 100) {
            return;
        }

        if (newInfill % 10 !== 0) {
            return;
        }

        setInfill(newInfill);
        setSpecsChanged(true);
    }

    /**
     * Handles the change event of the customer note. The state variable for the `customerNote` is updated.
     * The length of the note is limited to 500 characters.
     *
     * @param event -
     * @param data -
     */
    function changeCustomerNote (event: React.SyntheticEvent<HTMLElement>, data: TextAreaProps): void {
        const newNote = data.value as string;

        if (newNote.length > 500) {
            setCustomerNote(newNote.substring(0, 500));
            return;
        }

        setCustomerNote(newNote);
    }


    /**
     * Function takes in the compatible print options of a design, which was retrieved from the backend. It then
     * generates a list of dropdown options for the procedure based on given compatible options.
     *
     * @param compatibleOptions - The compatible print options of a design, generated by the backend.
     * @returns List of dropdown options for the procedure. If no compatible options are given, an empty list is returned.
     */
    function getProcedureDropdownOptions (compatibleOptions: CompatiblePrintOptions): DropdownItemProps[] {
        const options: DropdownItemProps[] = [];

        for (const procedure in compatibleOptions) {

            if (!procedure) {
                continue;
            }

            options.push({
                key: procedure,
                value: procedure,
                text: t(`printOptions:procedures.${ procedure }.alias`),
            });
        }

        if (!("fdm" in compatibleOptions)) {
            options.push({
                key: "fdm",
                value: "fdm",
                text: t("printOptions:procedures.fdm.alias"),
                disabled: true,
                selected: false,
            });
        }

        if (!("sla" in compatibleOptions)) {
            options.push({
                key: "sla",
                value: "sla",
                text: t("printOptions:procedures.sla.alias"),
                disabled: true,
                selected: false,
            });
        }

        return options;
    }

    /**
     * Function takes in the currently selected procedure for the variant and the compatible print options of a design.
     * It then generates a list of dropdown options for the material based on given arguments.
     *
     * @param currentProcedure - The currently selected procedure for the variant.
     * @param compatibleOptions - The compatible print options of a design, generated by the backend.
     * @returns List of dropdown options for the material. If no compatible options are given, or the current procedure
     * does not exist in the compatible options, an empty list is returned.
     */
    function getMaterialDropdownOptions (currentProcedure: Procedure, compatibleOptions: CompatiblePrintOptions): DropdownItemProps[] {
        const options: DropdownItemProps[] = [];

        if (!(currentProcedure in compatibleOptions)) {
            return [];
        }

        for (const materialType in compatibleOptions[currentProcedure]) {
            options.push({
                key: materialType,
                value: materialType,
                text: t(`printOptions:materials.${ materialType }.alias`),
                description: t(`printOptions:materials.${ materialType }.fullName`),
                content: (
                    <>
                        <Popup
                            wide="very"
                            content={
                                <MaterialProsAndCons
                                    pros={t(`printOptions:materials.${ materialType }.pros`, { returnObjects: true })}
                                    cons={t(`printOptions:materials.${ materialType }.cons`, { returnObjects: true })}
                                />
                            }
                            trigger={<Icon name="info circle"
                                link/>}
                        />
                        <span>{t(`printOptions:materials.${ materialType }.alias`)}</span>
                    </>
                ),
            });
        }

        return options;

    }


    /**
     * Takes in the currently selected procedure and material for the variant and the compatible print options of a design.
     * It then generates a list of dropdown options for the color based on given arguments.
     *
     * @param currentProcedure - The currently selected procedure for the variant.
     * @param currentMaterial - The currently seleected material for the variant.
     * @param compatibleOptions - The compatible print options of a design. generated by the backend.
     * @returns List of dropdown options for the color. If no compatible color can be found, based on `currentProcedure`,
     * `currentMaterial`, and `compatibleOptions`, an empty list is returned.
     */
    function getColorDropdownOptions (currentProcedure: Procedure, currentMaterial: Material, compatibleOptions: CompatiblePrintOptions): DropdownItemProps[] {
        const options: DropdownItemProps[] = [];

        if (!(currentProcedure in compatibleOptions)) {
            return [];
        }

        if (!(currentMaterial in compatibleOptions[currentProcedure])) {
            return [];
        }

        const material = compatibleOptions[currentProcedure][currentMaterial];

        const sortedColors = [ ...material.colors ].sort((a: ColorId, b: ColorId) => {
            const ralCodeA = config.colors[a]?.ralCode;
            const ralCodeB = config.colors[b]?.ralCode;
            return (ralCodeA ?? 0) - (ralCodeB ?? 0);
        });

        const blackColors = sortedColors.filter((colorId) => colorId.includes("black"));
        const whiteColors = sortedColors.filter((colorId) => colorId.includes("white"));
        const otherColors = sortedColors.filter((colorId) => !colorId.includes("white") && !colorId.includes("black"));

        const colors = [ ...blackColors, ...whiteColors, ...otherColors ];

        for (const colorId of colors) {
            options.push({
                key: colorId,
                value: colorId,
                text: t(`printOptions:colors.${ colorId }.alias`),
                label: config.colors[colorId]?.ralCode ? { color: config.colors[colorId].labelColor, content: `RAL ${ config.colors[colorId].ralCode }`, horizontal: true } : null,
            });
        }

        return options;
    }


    useEffect(() => {
        setSpecsChanged(false);
    }, []);

    useEffect(() => {
        if (variant.editMode) {
            startEdit();

        }
    }, [ variant.editMode ]);

    return (
        <>
            <Modal
                size="large"
                open={variant.editMode}
                closeOnDimmerClick={false}
                closeOnEscape={true}
                onClose={cancelEdit}
                closeIcon={allowCancel()}
            >
                <Modal.Header style={{ wordWrap: "anywhere", overflowWrap: "anywhere" }}>{t("variants:specifications.edit.title")}</Modal.Header>
                <Modal.Content>
                    <Grid
                        columns={2}
                        divided
                        stackable
                        doubling
                    >
                        <Grid.Column
                            width={12}
                        >
                            {
                                variant.error !== null
                                    ? <Message error>{variant.error}</Message>
                                    : <></>
                            }

                            <Form loading={saveInProgress}>
                                <Form.Group>
                                    <Form.Dropdown
                                        selection
                                        openOnFocus
                                        label={
                                            <>
                                                <Popup
                                                    wide="very"
                                                    content={t("variants:specifications.edit.infoTexts.procedure")}
                                                    trigger={<Icon name="info circle"
                                                        link/>}
                                                />
                                                {t("variants:specifications.fieldTitles.procedure")}
                                            </>
                                        }
                                        value={procedure}
                                        options={getProcedureDropdownOptions(design.compatiblePrintOptions)}
                                        onChange={changeProcedure}
                                        disabled={saveInProgress}
                                        width={8}
                                    />

                                    <Form.Dropdown
                                        selection
                                        openOnFocus
                                        label={
                                            <>
                                                <Popup
                                                    wide="very"
                                                    content={t("variants:specifications.edit.infoTexts.material")}
                                                    trigger={<Icon name="info circle"
                                                        link/>}
                                                />
                                                {t("variants:specifications.fieldTitles.material")}
                                            </>
                                        }
                                        value={material}
                                        options={getMaterialDropdownOptions(procedure, design.compatiblePrintOptions)}
                                        onChange={changeMaterial}
                                        disabled={saveInProgress}
                                        width={8}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Dropdown
                                        selection
                                        label={
                                            <>
                                                <Popup
                                                    wide="very"
                                                    content={t("variants:specifications.edit.infoTexts.color")}
                                                    trigger={<Icon name="info circle"
                                                        link/>}
                                                />
                                                {t("variants:specifications.fieldTitles.color")}
                                            </>
                                        }
                                        value={colorId}
                                        openOnFocus
                                        options={getColorDropdownOptions(procedure, material, design.compatiblePrintOptions)}
                                        onChange={changeColor}
                                        disabled={saveInProgress}
                                        width={6}
                                    />

                                    <Form.Input
                                        type="number"
                                        label={
                                            <>
                                                <Popup
                                                    wide="very"
                                                    content={t("variants:specifications.edit.infoTexts.quantity")}
                                                    trigger={<Icon name="info circle"
                                                        link/>}
                                                />
                                                {t("variants:specifications.fieldTitles.quantity")}
                                            </>
                                        }
                                        value={quantity}
                                        error={quantity === null}
                                        min={1}
                                        max={config.variants.maxQuantity}
                                        name="amount"
                                        step={1}
                                        onChange={changeQuantity}
                                        disabled={saveInProgress}
                                        width={4}
                                    />

                                    <Form.Dropdown
                                        selection
                                        label={
                                            <>
                                                <Popup
                                                    wide="very"
                                                    content={t("variants:specifications.edit.infoTexts.infill")}
                                                    trigger={<Icon name="info circle"
                                                        link/>}
                                                />
                                                {t("variants:specifications.fieldTitles.infill")}
                                            </>
                                        }
                                        value={procedure === "sla" ? "" : infill}
                                        placeholder={t("variants:specifications.edit.infillNotApplicable")}
                                        openOnFocus
                                        options={[
                                            { key: 10, text: "10 %", value: 10 },
                                            { key: 20, text: "20 %", value: 20 },
                                            { key: 30, text: "30 %", value: 30 },
                                            { key: 40, text: "40 %", value: 40 },
                                            { key: 50, text: "50 %", value: 50 },
                                            { key: 60, text: "60 %", value: 60 },
                                            { key: 70, text: "70 %", value: 70 },
                                            { key: 80, text: "80 %", value: 80 },
                                            { key: 90, text: "90 %", value: 90 },
                                            { key: 100, text: "100 %", value: 100 },
                                        ]}
                                        onChange={changeInfill}
                                        disabled={saveInProgress || procedure === "sla"}
                                        width={6}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <TextArea
                                        placeholder={t("variants:specifications.edit.remarksPlaceholder")}
                                        value={customerNote}
                                        onChange={changeCustomerNote}
                                        width={16}
                                        rows={5}
                                    />
                                </Form.Group>
                            </Form>
                        </Grid.Column>
                        <Grid.Column
                            floated="right"
                            width={4}
                        >
                            <Segment basic>
                                <Header
                                    as="h3"
                                    style={{ wordWrap: "anywhere", overflowWrap: "anywhere" }}
                                >
                                    {design.fileAlias}
                                </Header>

                                <List
                                    size="large"
                                    divided
                                >
                                    <List.Item>
                                        <List.List>
                                            <List.Description
                                                style={{ float: "left" }}
                                            >
                                                <Price
                                                    variantId={variant.id}
                                                    totalPrice={false}
                                                    showQuantity={true}
                                                />
                                            </List.Description>
                                            <List.Header
                                                style={{ float: "right" }}
                                            >
                                                <Price
                                                    variantId={variant.id}
                                                    totalPrice={true}
                                                    showQuantity={false}
                                                />
                                            </List.Header>
                                        </List.List>
                                    </List.Item>
                                </List>
                                <Message
                                    info
                                    content={t("variants:specifications.edit.priceUpdateInfo")}
                                    hidden={!specsChanged}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color="black"
                        content={t("common:buttons.update")}
                        loading={saveInProgress}
                        disabled={cancelInProgress || saveInProgress || design.isSlicing || quantity === null}
                        onClick={saveEdit}
                    />
                    <Button
                        color="teal"
                        content={t("common:buttons.apply")}
                        loading={saveInProgress}
                        disabled={cancelInProgress || saveInProgress || design.isSlicing || quantity === null}
                        onClick={saveEditAndClose}
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
}
