import { AxiosError } from "axios";
import { t } from "i18next";

import { ServiceError } from "./types";

export class VariantErrorHandler {
    static init (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;

        return {
            statusCode,
            message: t("messages:variants.init.error"),
        };
    }

    static getPrice (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message: string;

        switch (statusCode) {
        case 422:
            message = t("messages:variants.price.error");
            break;
        case 429:
            message = t("messages:variants.specifications.tooManyRequests");
            break;
        default:
            message = t("messages:variants.price.error");
            break;
        }

        return { statusCode, message };
    }

    static delete (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;

        return {
            statusCode,
            message: t("messages:variants.delete.error"),
        };
    }

    static updateSpecifications (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message: string;

        switch (statusCode) {
        case 404:
            message = t("messages:variants.notFound");
            break;
        case 422:
            message = t("messages:variants.specifications.invalid");
            break;
        default:
            message = t("messages:variants.specifications.updateError");
            break;
        }

        return { statusCode, message };
    }
}
