import axios from "axios";
import { t } from "i18next";

import { ServiceError } from "../errors/types";
import { VariantErrorHandler } from "../errors/variant";
import { PrintOptionsState } from "../states/print";
import { VariantState } from "../states/variant";


export class VariantService {

    /**
     * Method to initialize a new variant.
     *
     * @param orderId - The id of the order the variant belongs to.
     * @param designId - The id of the design the variant is based on.
     * @returns If the request was successful, the variant is returned. Otherswise `undefined` will be returned.
     */
    static async init (orderId: string, designId: string): Promise<VariantState | ServiceError> {
        return axios.post(`${ process.env.REACT_APP_BACKEND_HOST }/variant/${ orderId }/${ designId }`)
            .then((response) => {
                const variant = response.data;
                const printOptions = variant["printOptions"] as PrintOptionsState;

                return {
                    id: variant["_id"],
                    designId: variant["designId"],
                    quantity: variant["quantity"],
                    printOptions: printOptions,
                    pricePerPiece: undefined,
                    totalPrice: undefined,
                    editMode: false,
                    isDeleting: false,
                    isValid: true,
                    error: null,
                    customerNote: null,
                };

            })
            .catch((error) => {
                return VariantErrorHandler.init(error);
            });
    }

    /**
     * Method to update the specifications of a variant. This includes `quantity` and `printOptions`.
     *
     * **Note:** If the `printOptions.procedure` is set to `Procedure.SLA`, the `printOptions.infill` is set to `100`.
     *
     * @param variantId - The id of the variant
     * @param printOptions - The updated print options
     * @param quantity - The updated quantity
     * @param customerNote - The updated customer note
     * @returns If the request was successful, `true` is returned. Otherwise `false` is returned.
     */
    static async updateSpecifications (variantId: string, printOptions: PrintOptionsState, quantity: number, customerNote: string | null): Promise<void | ServiceError> {
        if (printOptions.procedure === "sla") {
            printOptions.infill = 100;
        }

        return axios.put(`${ process.env.REACT_APP_BACKEND_HOST }/variant/${ variantId }/specifications`, {
            "print_options": printOptions,
            "quantity": quantity,
            "customer_note": customerNote,
        })
            .then(() => {
                return;
            })
            .catch((error) => {
                return VariantErrorHandler.updateSpecifications(error);
            });
    }

    /**
     * Method to remove a variant from an order.
     *
     * @param variantId - The id of the variant to remove
     * @returns Whether the request was successful or not.
     */
    static async delete (variantId: string): Promise<void | ServiceError> {
        return axios.delete(`${ process.env.REACT_APP_BACKEND_HOST }/variant/${ variantId }`)
            .then(() => {
                return;
            })
            .catch((error) => {
                return VariantErrorHandler.delete(error);
            });
    }

    /**
     * Retrieves the price of a variant. The price returned encompasses the price of the entire variant
     * (i.e. If a user order a variant with `quantity = 2`, the price returned will be the price of two)
     *
     * @param variantId - The id of the variant
     * @returns The price of the variant, or `undefined` if the request failed.
     */
    static async getPrice (variantId: string): Promise<number | ServiceError> {
        const maxRetries = 10;
        let retries = 0;
        let response = undefined;

        while(!response && retries < maxRetries) {
            response = await axios.get(`${ process.env.REACT_APP_BACKEND_HOST }/variant/${ variantId }/price`)
                .then((response) => {
                    return response.data["pricePerPiece"];

                })
                .catch((error) => {
                    return VariantErrorHandler.getPrice(error);
                });

            if (!response) {
                const timeout = 5000;
                await new Promise((resolve) => setTimeout(resolve, timeout));
            }

            retries++;
        }

        if (!response) {
            return {
                statusCode: 500,
                message: t("messages:variants.price.error"),
            } as ServiceError;
        }

        return response;
    }
}
