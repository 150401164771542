import React, {
    ReactElement, useEffect, useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Item,
    Message, Placeholder, Progress,
} from "semantic-ui-react";

import { designStore } from "../../redux/slices/design";
import { variantStore } from "../../redux/slices/variant";
import { RootState } from "../../redux/store";
import { DesignService } from "../../service/design";
import {
    designIsProcessed, DesignState, formatDimensions,
} from "../../states/design";
import DesignAnalysis from "./DesignAnalysis";
import DesignPreview from "./Preview";

/**
 * @param fileAlias - Unique alias of the design to display
 * @returns Represents a single design in the `DesignList` component.
 */
export default function Design (props: { fileAlias: string}): ReactElement {
    const dispatch = useDispatch();
    const design = useSelector((state: RootState) => state.designs[props.fileAlias]);

    const [ enableDeletion, setEnableDeletion ] = useState<boolean>(false);
    const [ designProgress, setDesignProgress ] = useState<number>(0);

    const [ t ] = useTranslation([ "common" ]);

    if (!design) {
        return (<></>);
    }

    /**
     * @param design - The design to purge.
     */
    async function purgeDesign (design: DesignState): Promise<void> {
        dispatch(designStore.setIsDeleting({ fileAlias: design.fileAlias, isDeleting: true }));

        if (design.id) {
            const designWasPurged = await DesignService.purge(design.id);

            if (!designWasPurged) {
                dispatch(designStore.setError({ fileAlias: design.fileAlias, error: "Modell konnte nicht gelöscht werden." }));
                dispatch(designStore.setIsDeleting({ fileAlias: design.fileAlias, isDeleting: false }));
                return;
            }
        }


        if (design.id) {
            dispatch(variantStore.deleteDesignVariants(design.id));
        }

        dispatch(designStore.deleteDesign(design.fileAlias));
    }


    useEffect(() => {
        if (!designIsProcessed(design)) {
            setEnableDeletion(true);
            return;
        }
    }, [ design ]);

    useEffect(() => {
        let progressPercent = 0;

        if (!design.isUploading) {
            progressPercent += 100 / 3;
        }

        if (design.dimensions) {
            progressPercent += 100 / 3;
        }

        if (design.compatiblePrintOptions) {
            progressPercent += 100 / 3;
        }


        setDesignProgress(design.uploadProgress || progressPercent);

    }, [ design.isUploading, design.publicUrl, design.dimensions, design.compatiblePrintOptions, design.uploadProgress ]);

    return (
        <>
            <Item>
                <Item.Image>
                    <DesignPreview fileAlias={design.fileAlias}
                        size={{ height: 150, width: 150 }}/>
                </Item.Image>

                <Item.Content floated="right">
                    <Item.Header style={{ wordWrap: "anywhere", overflowWrap: "anywhere" }}>
                        <DesignAnalysis design={design} />
                        {design.fileAlias}
                    </Item.Header>
                    {
                        !design.dimensions ?
                            (!design.error ?
                                <Placeholder>
                                    <Placeholder.Line length="medium"/>
                                </Placeholder> :
                                <br />
                            )
                            :
                            <Item.Meta>{formatDimensions(design.dimensions)}</Item.Meta>
                    }

                    <Progress
                        size="small"
                        percent={designProgress}
                        active
                        autoSuccess
                        disabled={design.error !== null}
                        error={design.error !== null}
                        style={{ marginTop: "20px" , marginBottom: "10px" }}
                    />

                    <Item.Extra>
                        <Button
                            negative
                            floated="right"
                            disabled={!enableDeletion || design.isDeleting}
                            loading={design.isDeleting}
                            content={t("common:buttons.remove")}
                            onClick={(): Promise<void> => purgeDesign(design)}
                        />
                    </Item.Extra>

                    <Message
                        error
                        icon="exclamation circle"
                        hidden={!design.error}
                        content={design.error}
                    />

                </Item.Content>
            </Item>
        </>
    );
}
