import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    Button, Card, Message,
} from "semantic-ui-react";

import { isServiceError } from "../../errors/types";
import { designStore } from "../../redux/slices/design";
import { variantStore } from "../../redux/slices/variant";
import { RootState } from "../../redux/store";
import { VariantService } from "../../service/variant";
import SpecificationsEdit from "./specifications/SpecificationsEdit";
import SpecificationsShow from "./specifications/SpecificationsShow";

/**
 * @param variantId - unique identifier of the variant
 * @returns Represents single variant in the `VariantList` of a `Design`.
 */
export default function Variant (props: { fileAlias: string, variantId: string}): ReactElement {
    const dispatch = useDispatch();

    const design = useSelector((state: RootState) => state.designs[props.fileAlias]);
    const variant = useSelector((state: RootState) => state.variants[props.variantId]);

    const [ t ] = useTranslation([ "common" ]);

    if (!design) {
        return (<></>);
    }

    if (!variant) {
        return (<></>);
    }

    /**
     * Handles the process to delete a variant from the server and the redux store.
     */
    async function deleteVariant (): Promise<void> {
        dispatch(variantStore.setIsDeleting({ variantId: variant.id, isDeleting: true }));

        const response = await VariantService.delete(variant.id);

        if (isServiceError(response)) {
            dispatch(variantStore.setError({ variantId: variant.id, error: response.message }));
            dispatch(variantStore.setIsDeleting({ variantId: variant.id, isDeleting: false }));
        } else {
            dispatch(variantStore.deleteVariant(variant.id));
        }
    }


    /**
     * Updates the price of the variant. This function uses the `VariantService` to send a request to the server.
     * The redux store is updated accordingly.
     */
    async function updatePrice (): Promise<void> {
        dispatch(designStore.setIsSlicing({ fileAlias: design.fileAlias, isSlicing: true }));
        const response = await VariantService.getPrice(variant.id);

        if (isServiceError(response)) {
            dispatch(variantStore.setError({ variantId: variant.id, error: response.message }));
            dispatch(designStore.setIsSlicing({ fileAlias: design.fileAlias, isSlicing: false }));
            return;
        }

        dispatch(variantStore.setPrice({ variantId: variant.id, pricePerPiece: response }));
        dispatch(designStore.setIsSlicing({ fileAlias: design.fileAlias, isSlicing: false }));
    }

    useEffect(() => {
        updatePrice();
    }, [ variant.printOptions, variant.quantity ]);

    return (
        <>
            {
                variant.editMode ?
                    (<SpecificationsEdit variantId={variant.id}/>) :
                    (<></>)
            }

            <Card fluid>
                <Card.Content>
                    <SpecificationsShow variantId={variant.id} />
                </Card.Content>

                <Card.Content extra>
                    <Button
                        color="black"
                        floated="right"
                        loading={variant.editMode}
                        disabled={variant.isDeleting || variant.editMode}
                        onClick={(): void => {dispatch(variantStore.toggleEditMode(variant.id));}}
                        content={t("common:buttons.edit")}
                    />

                    <Button
                        negative
                        floated="right"
                        disabled={variant.isDeleting || variant.editMode}
                        loading={variant.isDeleting}
                        onClick={deleteVariant}
                        content={t("common:buttons.remove")}
                    />
                </Card.Content>

                <Message
                    attached="bottom"
                    hidden={!variant.error}
                    error
                >
                    {variant.error}
                </Message>
            </Card>
        </>
    );
}
