import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import config from "../config/config";

/**
 * Resolves the language to use based on the provided locale and the resolved language of the browser.
 *
 * Precedence is chosen as follows:
 * 1. Locale provided in the URL.
 * 2. Language resolved by the browser.
 * 3. Default Language.
 *
 * If any of the provided locales is not available or invalid, the next precedence is chosen.
 *
 * @param paramLanguage - The locale provided in the URL.
 * @param detectedLanguage - The language detected by the browser.
 *
 * @returns The locale to use.
 */
export function resolveLanguage (paramLanguage: string | undefined, detectedLanguage: string | undefined): string {
    if (paramLanguage) {
        // ? Possible formats: "de", "de-DE"
        const paramLng = paramLanguage.split("-")[0];
        if (config.language.available.includes(paramLng)) {
            return paramLng;
        }
    }

    if (detectedLanguage) {
        // ? Possible formats: "de", "de-DE"
        const detectedLng = detectedLanguage.split("-")[0];
        if (config.language.available.includes(detectedLng)) {
            return detectedLng;
        }
    }

    return config.language.default;
}


i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: config.language.fallback,
        ns: [
            "common",
            "navbar",
            "footer",
            "home",
            "specifications",
            "steps",
            "order",
            "designs",
            "variants",
            "checkout",
            "messages",
            "userGuide",
            "shippingConditions",
            "printOptions",
        ],
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        debug: process.env.NODE_ENV === "development",
        interpolation: {
            escapeValue: false,
        },
    });


export default i18n;
