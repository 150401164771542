import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
    Grid,
    Header,
    List,
    Progress,
} from "semantic-ui-react";

import config from "../../config/config";


export default function DesignStep () : ReactElement {
    const [ t ] = useTranslation([ "home" ]);


    return <Grid.Row>

        <Grid.Column width={ 8 }>
            <Header as="h3">{t("orderProcess.steps.design.title")}</Header>
            <p>
                {
                    t(
                        "orderProcess.steps.design.content",
                        {
                            extensions:  config.files.extensions.slice(0, config.files.extensions.length - 1).join(", "),
                            lastExtension: config.files.extensions[config.files.extensions.length - 1],
                        },
                    )
                }
            </p>
        </Grid.Column>
        <Grid.Column width={ 8 }>
            <List>
                <List.Item>
                    <List.Icon name="file" />
                    <List.Content>sphere.stl</List.Content>
                    <Progress
                        style={{ marginTop: "0.5em" }}
                        percent={100}
                        indicating
                        size="small"
                        success
                    />
                </List.Item>
                <List.Item>
                    <List.Icon name="file" />
                    <List.Content>cone.obj</List.Content>
                    <Progress
                        style={{ marginTop: "0.5em" }}
                        percent={100}
                        indicating
                        size="small"
                        success
                    />
                </List.Item>
            </List>
        </Grid.Column>
    </Grid.Row>;
}
