/* eslint-disable react/no-unknown-property */
import React, {
    ReactElement, Suspense, useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import {
    Grid, Header, Segment,
} from "semantic-ui-react";
import * as THREE from "three";

import { fitCameraFactory } from "../../utils/three";
import DemoCanvas, { FallbackPreview } from "./DemoCanvas";
import DemoMesh from "./DemoMesh";


/**
 * @param sphereProgress - If progress is 100, the sphere mesh is displayed
 * @param coneProgress - If progress is 100, the cone mesh is displayed
 */
export default function CheckoutStep (): ReactElement {
    const [ t ] = useTranslation([ "home" ]);
    const camera = useMemo(() => {return new THREE.PerspectiveCamera( 20, 1, 10, 250 );}, []);


    return (
        <Grid.Row>
            <Grid.Column width={ 8 }>
                <Header as="h3">{t("orderProcess.steps.checkout.title")}</Header>
                <p>{t("orderProcess.steps.checkout.content")} <sup>2)</sup></p>
            </Grid.Column>
            <Grid.Column width={ 8 }>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Segment
                        basic
                        textAlign="center"
                    >

                        <DemoCanvas camera={camera}>
                            <Suspense fallback={<FallbackPreview />}>
                                <DemoMesh
                                    fitCamera={fitCameraFactory(camera)}
                                />
                            </Suspense>
                        </DemoCanvas>
                    </Segment>
                </div>
            </Grid.Column>
        </Grid.Row>
    );
}
