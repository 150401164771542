import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Header, Message } from "semantic-ui-react";

import { RootState } from "../../redux/store";
import DesignList from "./DesignList";
import DesignUpload from "./DesignUpload";


/**
 * This represents the designs page, where user can upload their designs.
 * @returns The designs page
 */
export default function Designs (): ReactElement {
    const order = useSelector((state: RootState) => state.order);
    const [ t ] = useTranslation([ "designs" ]);

    return (
        <>
            <Header as="h1">{t("designs:header")}</Header>

            <Message
                error
                visible={order.error !== null}
                hidden={order.error === null}
            >
                {order.error}
            </Message>

            <DesignList/>
            <DesignUpload/>
        </>
    );
}
