export type Procedure = string & ("fdm" | "sla");
export type Material = string & (
    "abs"
    | "asa"
    | "nylon"
    | "petg"
    | "pla"
    | "plaplus"
    | "tpu"
    | "resin-standard"
    | "resin-tough"
    | "resin-abs");

export type ColorId = string & (
    "black"
    | "black-traffic"
    | "white"
    | "white-traffic"
    | "white-signal"
    | "grey"
    | "grey-aluminium"
    | "grey-iron"
    | "red-traffic"
    | "green-traffic"
    | "blue-ultramarine"
    | "blue-sky"
    | "orange-pure");

type Config = {
    language: {
        available: string[],
        fallback: string[],
        default: string,
    },
    banner: {
        show: boolean,
    },
    files: {
        maxSize: number,
        extensions: string[],
    },
    order: {
        maxDesignsperOrder: number,
    },
    designs: {
        defaultPreviewUrl: string,
        maxVariantsPerDesign: number
        simultaneousPartUploads: number,
    },
    variants: {
        maxQuantity: number,
    },
    colors: { [key in ColorId]: {
        ralCode: number | undefined;
        labelColor: string | undefined;
    }
}
}

const config: Config = {
    language: {
        available: [ "en", "de" ],
        fallback: [ "en", "de" ],
        default: "en",
    },
    banner: {
        show: true,
    },
    // configs for allowed files
    files: {
        maxSize: 150 * 1024 ** 2,             // max 150 MB
        // allowed file extension (add new ones in lowercase)
        // ! extension must include the dot
        extensions: [ ".stl", ".obj", ".step", ".stp", ".3mf", ".amf" ],
    },
    order: { maxDesignsperOrder: 5 },
    designs: {
        defaultPreviewUrl: "/img/brand_logo_black_with_name.svg",
        maxVariantsPerDesign: 5,
        simultaneousPartUploads: 5,
    },
    variants: { maxQuantity: 10 },
    colors: {
        "black": {
            labelColor: "black",
            ralCode: undefined,
        },
        "black-traffic": {
            labelColor: "black",
            ralCode: 9017,
        },
        "white": {
            labelColor: "",
            ralCode: undefined,
        },
        "white-traffic": {
            labelColor: "",
            ralCode: 9016,
        },
        "white-signal": {
            labelColor: "",
            ralCode: 9003,
        },
        "grey": {
            labelColor: "grey",
            ralCode: undefined,
        },
        "grey-aluminium": {
            labelColor: "grey",
            ralCode: 9007,
        },
        "grey-iron": {
            labelColor: "grey",
            ralCode: 7011,
        },
        "red-traffic": {
            labelColor: "red",
            ralCode: 3020,
        },
        "green-traffic": {
            labelColor: "green",
            ralCode: 6024,
        },
        "blue-ultramarine": {
            labelColor: "blue",
            ralCode: 5002,
        },
        "blue-sky": {
            labelColor: "blue",
            ralCode: 5015,
        },
        "orange-pure": {
            labelColor: "orange",
            ralCode: 2004,
        },
    },
};

export default config;
