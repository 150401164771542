import "./App.css";

import React, { ReactElement } from "react";
import {
    BrowserRouter, Route, Routes,
} from "react-router-dom";

import Error404 from "./pages/errors/404";
import Error429 from "./pages/errors/429";
import Error500 from "./pages/errors/500";
import Home from "./pages/Home";
import DataProtection from "./pages/policies/DataProtection";
import GeneralTermsConditions from "./pages/policies/GeneralTermsConditions";
import Imprint from "./pages/policies/Imprint";
import RightsOfWithdrawal from "./pages/policies/RightsOfWithdrawal";
import ShippingConditions from "./pages/policies/ShippingConditions";
import PrintOnDemand from "./pages/PrintOnDemand";


/**
 * @returns Main component of the application.
 */
function App (): ReactElement {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/404"
                    element={<Error404/>}/>
                <Route path="/429"
                    element={<Error429/>}/>
                <Route path="/500"
                    element={<Error500/>}/>
                <Route path="/*"
                    element={<Error404/>}/>
                <Route path="/policies/terms-of-service"
                    element={<GeneralTermsConditions/>} />
                <Route path="/policies/data-protection"
                    element={<DataProtection/>}/>
                <Route path="/policies/imprint"
                    element={<Imprint/>}/>
                <Route path="/policies/rights-of-withdrawal"
                    element={<RightsOfWithdrawal/>}/>
                <Route path="/policies/shipping-conditions"
                    element={<ShippingConditions/>}/>
                <Route path="/:orderId/models"
                    element={<PrintOnDemand/>}/>
                <Route path="/:orderId/variants"
                    element={<PrintOnDemand/>}/>
                <Route path="/"
                    element={<Home/>}/>
                <Route path="/:language/"
                    element={<Home/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
