import React, {
    ReactElement, useEffect, useState,
} from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button, Grid, GridColumn,
    Loader, Message, Modal, Segment,
} from "semantic-ui-react";

import Designs from "../components/designs/Designs";
import SideMenu from "../components/side-menu/SideMenu";
import Steps from "../components/Steps";
import Variants from "../components/variants/Variants";
import { isServiceError } from "../errors/types";
import { designStore } from "../redux/slices/design";
import { orderStore } from "../redux/slices/order";
import { variantStore } from "../redux/slices/variant";
import { RootState } from "../redux/store";
import { DesignService } from "../service/design";
import { OrderService } from "../service/order";
import { VariantService } from "../service/variant";
import { DesignState, dimensionsAreValid } from "../states/design";
import { VariantState } from "../states/variant";
import PageTemplate from "../templates/PageTemplate";


/**
 * @returns The print on demand page.
 */
export default function PrintOnDemand (): ReactElement {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let isMounted = true;

    const { orderId: paramOrderId } = useParams<{ orderId: string }>();
    const order = useSelector((state: RootState) => state.order);

    const [ reopen, setReopen ] = useState(false);
    const [ reopenLoading, setReopenLoading ] = useState(false);
    const [ reopenError, setReopenError ] = useState<boolean>(false);

    const [ t ] = useTranslation();

    /**
     * Function handles the `popstate` event, which is triggered when the user navigates back to the
     */
    function handlePopState (): void {
        clearState();
    }

    window.addEventListener("popstate", handlePopState);


    /**
     * Function tries to retrieve the order from the backend. If successful, the order id will be set
     * in the redux store.
     * If unsuccessful, the user will be navigated to the `404` page.
     *
     * @param orderId - The id of the order to retrieve.
     */
    async function loadOrder (orderId: string): Promise<void> {
        const response = await OrderService.get(orderId);

        if (isServiceError(response)) {
            dispatch(orderStore.setError(response.message)),
            // TODO: set toast notification with button to return to home page
            // ! after migrating to mantine
            navigate("/404");
            return;
        }

        const requestedOrder = response;
        if (!requestedOrder?.id) {
            dispatch(orderStore.setError(t("messages:order.get.notFound")));
            navigate("/404");
            return;
        }

        setStep();

        if (requestedOrder.error) {
            dispatch(orderStore.setError(t("messages:order.get.error")));
            setReopen(true);
            return;
        }

        dispatch(orderStore.setId(requestedOrder.id));
    }

    /**
     * Sets the `order.step` in the redux store based on the url.
     * If the url ends with `models`, the step will be set to `DESIGNS`.
     * If the url ends with `variants`, the step will be set to `VARIANTS`.
     * If it neither ends with `designs` nor `variants`, the user will be navigated to the `404` page.
     */
    function setStep (): void {
        const url = window.location.href;

        if (url.endsWith("models")) {
            dispatch(orderStore.setStep("DESIGNS"));
            return;
        } else if (url.endsWith("variants")) {
            dispatch(orderStore.setStep("VARIANTS"));
            return;
        }

        dispatch(orderStore.setError(t("messages:order.get.notFound")));
        navigate("/404");
    }

    /**
     * Function retrieves designs for the given order from the backend.
     * If required information is missing, such as the preview url, dimensions or compatible print options,
     * they will be requested from the backend and set accordingly in the redux store.
     *
     * @param orderId - The identifier of the order to request information for.
     */
    async function loadDesigns (orderId: string): Promise<void> {
        const response = await OrderService.getDesigns(orderId);
        if (isServiceError(response)) {
            dispatch(orderStore.setError(response.message));
            dispatch(orderStore.setDesignsLoaded(true));
            return;
        }

        const designs = response;
        dispatch(designStore.initExistingDesigns(designs));

        // TODO: the functionality here of loading a designs seems similar to the logic used in DesignUpload. Check
        // if this can be refactored into a shared function
        for (const design of Object.values(designs) as DesignState[]) {
            if (!design.id) {
                continue;
            }

            if (!design.publicUrl) {
                const response = await DesignService.getPublicUrl(design.id);
                if (!isServiceError(response)) {
                    dispatch(designStore.setPublicUrl({ fileAlias: design.fileAlias, publicUrl: response }));
                }
            }

            if (!design.dimensions || !dimensionsAreValid(design.dimensions)) {
                const response = await DesignService.getDimensions(design.id);
                if (isServiceError(response)) {
                    dispatch(designStore.setError({ fileAlias: design.fileAlias, error: response.message }));
                    continue;
                }

                const dimensions = response;

                if (!dimensions) {
                    dispatch(designStore.setError(
                        {
                            fileAlias: design.fileAlias,
                            error: t("messages:designs.dimensions.invalid"),
                        },
                    ));
                    continue;
                } else if (!dimensionsAreValid(dimensions)) {
                    if (!dimensions.manifold) {
                        dispatch(designStore.setError({ fileAlias: design.fileAlias, error: t("messages:designs.dimensions.nonManifold") }));
                    } else {
                        dispatch(designStore.setError({ fileAlias: design.fileAlias, error: t("messages:designs.dimensions.invalid") }));
                    }
                    continue;
                }

                dispatch(designStore.setDimensions({ fileAlias: design.fileAlias, dimensions }));
            }

            if (!design?.compatiblePrintOptions) {
                const response = await DesignService.getCompatiblePrintOptions(design.id);
                if (isServiceError(response)) {
                    dispatch(designStore.setError({ fileAlias: design.fileAlias, error: response.message }));
                    continue;
                }

                const compatiblePrintOptions = response;
                if (!compatiblePrintOptions) {
                    dispatch(designStore.setError(
                        {
                            fileAlias: design.fileAlias,
                            error: t("messages:designs.invalid"),
                        },
                    ));
                    continue;
                }

                dispatch(designStore.setCompatiblePrintOptions({ fileAlias: design.fileAlias, compatiblePrintOptions }));
            }
        }


        dispatch(orderStore.setDesignsLoaded(true));
    }

    /**
     * If the given order already contains variants, they will be requested from the
     * backend and set in the redux store accordingly.
     *
     * @param orderId - The identifier of the order to request information for.
     */
    async function loadVariants (orderId: string): Promise<void> {
        const response = await OrderService.getVariants(orderId);
        if (isServiceError(response)) {
            dispatch(orderStore.setError(response.message));
            dispatch(orderStore.setVariantsLoaded(true));
            return;
        }

        const variants = response;
        dispatch(variantStore.initExistingVariants(variants));

        for (const variant of Object.values(variants) as VariantState[]) {
            if (!variant.id) {
                continue;
            }

            if (!variant.pricePerPiece) {
                const response = await VariantService.getPrice(variant.id);

                if (isServiceError(response)) {
                    dispatch(variantStore.setError({ variantId: variant.id, error: response.message }));
                    continue;
                }

                dispatch(variantStore.setPrice({ variantId: variant.id, pricePerPiece: response }));
            }
        }

        dispatch(orderStore.setVariantsLoaded(true));
    }


    /**
     * Function tries to reopen the order. If successful, a reload is initiated.
     */
    async function reopenOrder (): Promise<void> {
        if (!paramOrderId) {
            setReopenLoading(false);
            setReopen(false);
            dispatch(orderStore.setError(t("messages:order.reopenFailed")));
            return;
        }

        setReopenLoading(true);

        const response = await OrderService.reopen(paramOrderId);

        if (!isServiceError(response)) {
            dispatch(orderStore.setId(paramOrderId));
            dispatch(orderStore.clearError());

            dispatch(orderStore.setDesignsLoaded(false));
            dispatch(orderStore.setVariantsLoaded(false));

            setReopenLoading(false);
            setReopenError(false);
            setReopen(false);
        } else {
            setReopenError(true);
            setReopenLoading(false);
            dispatch(orderStore.setError(response.message));
        }
    }

    /**
     * Function clears the redux store, in order to clear any remaining cached data.
     */
    function clearState (): void {
        dispatch(orderStore.clear());
        dispatch(designStore.clear());
        dispatch(variantStore.clear());
    }

    // ? Initial setup the following steps are performed:
    // ? - load the order
    // ? - set the step
    // ? - load the designs
    // ? - load the variants
    useEffect(() => {
        if (!paramOrderId) {
            dispatch(orderStore.setError(t("messages:order.get.notFound")));
            navigate("/404");
            return;
        }

        if (order.id) {
            return;
        }

        clearState();
        loadOrder(paramOrderId);

        return () => {
            isMounted = false;
            window.removeEventListener("popstate", handlePopState);

            if (!order.id) {
                return;
            }

            dispatch(orderStore.setError(t("messages:order.error")));
        };
    }, []);

    useEffect(() => {
        if (!order.id || order.error) {
            return;
        }

        if (!order.designsLoaded) {
            loadDesigns(order.id);
        }

        if (!order.variantsLoaded) {
            loadVariants(order.id);
        }
    }, [ order.id, order.error ]);

    useEffect(() => {
        if (order.designsLoaded && order.variantsLoaded) {
            setStep();
        }

    }, [ order.designsLoaded, order.variantsLoaded ]);


    return (
        <PageTemplate>
            <Grid
                centered
                stackable
                style={{
                    marginTop: "60px",
                    paddingBottom: "60px",
                }}
            >
                {
                    isMobile ?
                        <></> :
                        <Grid.Row>
                            <GridColumn width={16}>
                                <Steps/>
                            </GridColumn>
                        </Grid.Row>
                }
                <Grid.Row>
                    <Grid.Column width={2}></Grid.Column>

                    <Grid.Column width={8}>
                        <Segment basic>
                            { order.step === "DESIGNS" ? <Designs/> : <></> }
                            { order.step === "VARIANTS" ? <Variants/> : <></> }
                            { order.step == "CHECKOUT" ?
                                <div>
                                    <Segment basic>
                                        <Loader active
                                            content={t("order:redirectNote")}/>
                                    </Segment>
                                </div> : <></>}
                        </Segment>
                    </Grid.Column>

                    <Grid.Column width={3}>
                        <SideMenu/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Modal
                open={reopen || reopenError}
            >
                <Modal.Header>{t("order:reopenModal.title")}</Modal.Header>
                <Modal.Content>
                    {t("order:reopenModal.content")}
                    <br/>
                    <Message
                        error
                        hidden={!reopenError}
                    >
                        {order.error}
                    </Message>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={t("common:buttons.cancel")}
                        disabled={reopenLoading}
                        href="/"
                        color="black"
                    />
                    <Button content={t("common:buttons.reopenOrder")}
                        disabled={reopenLoading}
                        loading={reopenLoading}
                        onClick={reopenOrder}
                        color="teal"
                    />
                </Modal.Actions>
            </Modal>
        </PageTemplate>
    );
}
