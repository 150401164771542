import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Accordion,
    Grid,
    Header,
    Icon,
    Label,
    List,
    SemanticCOLORS,
} from "semantic-ui-react";

/**
 * Component to render a simple model demo using `three`.
 *
 * @returns the variant demo component
 */
export default function VariantStep (): ReactElement {
    const [ t ] = useTranslation([ "home", "specifications" ]);
    const [ accordionActiveStep, setAccordionActiveStep ] = useState<number | null>(null);

    function accordionClickHandler (index: number): void {
        if (accordionActiveStep === index) {
            setAccordionActiveStep(null);
        } else {
            setAccordionActiveStep(index);
        }
    }

    const fdmMaterialList: {text: string}[] = [
        { text: "PLA" },
        { text: "PLA+" },
        { text: "ABS" },
        { text: "ASA" },
        { text: "PETG" },
        { text: "Nylon" },
        { text: "TPU" },
    ];

    const slaMaterialList: {text: string}[] = [
        { text: t("material.sla.resin-standard.name", { ns: "specifications" }) },
        { text: t("material.sla.resin-tough.name", { ns: "specifications" }) },
        { text: t("material.sla.resin-abs-like.name", { ns: "specifications" }) },
    ];

    const fdmColorList: {labelColor: SemanticCOLORS | undefined, labelText: string, text: string}[] = [
        {
            text: t("color.fff.black-traffic", { ns: "specifications" }),
            labelColor: "black",
            labelText: "RAL 9017",
        },
        {
            text: t("color.fff.white-traffic", { ns: "specifications" }),
            labelColor: undefined,
            labelText: "RAL 9016",
        },
        {
            text: t("color.fff.white-signal", {  ns: "specifications" }),
            labelColor: undefined,
            labelText: "RAL 9003",
        },
        {
            text: t("color.fff.gray-aluminium", { ns: "specifications" }),
            labelColor: "grey",
            labelText: "RAL 9007",
        },
        {
            text: t("color.fff.gray-iron", { ns: "specifications" }),
            labelColor: "grey",
            labelText: "RAL 7011",
        },
        {
            text: t("color.fff.red-traffic", { ns: "specifications" }),
            labelColor: "red",
            labelText: "RAL 3020",
        },
        {
            text: t("color.fff.green-traffic", { ns: "specifications" }),
            labelColor: "green",
            labelText: "RAL 6024",
        },
        {
            text: t("color.fff.blue-ultramarine", {  ns: "specifications" }),
            labelColor: "blue",
            labelText: "RAL 5002",
        },
        {
            text: t("color.fff.blue-sky", {  ns: "specifications" }),
            labelColor: "blue",
            labelText: "RAL 5015",
        },
        {
            text: t("color.fff.orange-pure", { ns: "specifications" }),
            labelColor: "orange",
            labelText: "RAL 2004",
        },
    ];

    const slaColorList: {text: string}[] = [
        {
            text: t("color.sla.black", { ns: "specifications" }),
        },
        {
            text: t("color.sla.white", { ns: "specifications" }),
        },
        {
            text: t("color.sla.gray", { ns: "specifications" }),
        },
    ];

    return (
        <Grid.Row
            reversed="computer tablet"
        >
            <Grid.Column width={ 8 }>
                <Header as="h3">{t("orderProcess.steps.variant.title")}</Header>
                <p>{t("orderProcess.steps.variant.content")} <sup>1)</sup></p>
            </Grid.Column>

            <Grid.Column width={ 8 }>
                <Accordion
                    fluid
                    styled
                >
                    <Accordion.Title
                        active={ accordionActiveStep === 0 }
                        index={ 0 }
                        onClick={(): void => accordionClickHandler(0)}
                    >
                        <Icon name='dropdown' />
                        {t("orderProcess.steps.variant.accordion.material.title")}
                    </Accordion.Title>
                    <Accordion.Content
                        active={ accordionActiveStep === 0 }
                        index={ 0 }
                    >
                        {t("orderProcess.steps.variant.accordion.material.description")}
                        <br/> <br/>
                        <Grid
                            columns={2}
                            stackable
                        >
                            <Grid.Row>
                                <Grid.Column widht={8}>
                                    <Header as="h5">FDM</Header>
                                    <List bulleted>
                                        {
                                            fdmMaterialList.map((materialListItem) => (
                                                <ListMaterialItem
                                                    key={materialListItem.text}
                                                    text={materialListItem.text}
                                                />
                                            ))
                                        }
                                    </List>
                                </Grid.Column>
                                <Grid.Column widht={8}>
                                    <Header as="h4">SLA</Header>
                                    <List bulleted>
                                        {
                                            slaMaterialList.map((materialListItem) => (
                                                <ListMaterialItem
                                                    key={materialListItem.text}
                                                    text={materialListItem.text}
                                                />
                                            ))
                                        }
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Accordion.Content>

                    <Accordion.Title
                        active={ accordionActiveStep === 1 }
                        index={ 1 }
                        onClick={(): void => accordionClickHandler(1)}
                    >
                        <Icon name='dropdown' />
                        {t("orderProcess.steps.variant.accordion.color.title")}
                    </Accordion.Title>
                    <Accordion.Content
                        active={ accordionActiveStep === 1 }
                        index={ 1 }
                    >
                        {t("orderProcess.steps.variant.accordion.color.description")}
                        <br/> <br/>
                        <Grid
                            columns={2}
                            stackable
                        >
                            <Grid.Row>
                                <Grid.Column widht={8}>
                                    <Header as="h5">FDM</Header>
                                    <List bulleted>
                                        {
                                            fdmColorList.map((colorListItem) => (
                                                <ListColorItem
                                                    key={colorListItem.text}
                                                    labelColor={colorListItem.labelColor}
                                                    labelText={colorListItem.labelText}
                                                    text={colorListItem.text}
                                                />
                                            ))
                                        }
                                    </List>
                                </Grid.Column>
                                <Grid.Column widht={8}>
                                    <Header as="h4">SLA</Header>
                                    <List bulleted>
                                        {
                                            slaColorList.map((colorListItem) => (
                                                <ListColorItem
                                                    key={colorListItem.text}
                                                    labelColor={undefined}
                                                    labelText={undefined}
                                                    text={colorListItem.text}
                                                />
                                            ))
                                        }
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Accordion.Content>

                    <Accordion.Title
                        active={ accordionActiveStep === 2 }
                        index={ 2 }
                        onClick={(): void => accordionClickHandler(2)}
                    >
                        <Icon name='dropdown' />
                        {t("orderProcess.steps.variant.accordion.infill.title")}
                    </Accordion.Title>
                    <Accordion.Content
                        active={ accordionActiveStep === 2 }
                        index={ 2 }
                    >
                        {t("orderProcess.steps.variant.accordion.infill.description")}
                    </Accordion.Content>
                </Accordion>
            </Grid.Column>
        </Grid.Row>
    );
}


function ListColorItem (props: {labelColor: SemanticCOLORS | undefined, labelText: string | undefined, text: string}): ReactElement {
    return <List.Item>
        {props.labelText ?
            <Label
                color={props.labelColor}
                content={props.labelText}
                horizontal
            /> : null
        }
        {props.text}
    </List.Item>;
}

function ListMaterialItem (props: {text: string}): ReactElement {
    return <List.Item>
        {props.text}
    </List.Item>;
}
