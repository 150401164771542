import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Header, Segment } from "semantic-ui-react";

import config from "../config/config";


export default function Banner (): ReactElement {
    const [ t ] = useTranslation([ "common" ]);
    if (!config.banner.show) {
        return <></>;
    }

    return (
        <>
            <Segment
                basic
                vertical
                color="teal"
                inverted
                textAlign="center"
            >
                <Header as="h4">
                    {t("banner.description")}
                </Header>
            </Segment>
        </>
    );
}
