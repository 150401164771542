import { t } from "i18next";
import React, { ReactElement, useState } from "react";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Message,
    Modal,
} from "semantic-ui-react";

import config from "../../config/config";
import { orderStore } from "../../redux/slices/order";
import { RootState } from "../../redux/store";
import { formatFileSize } from "../../utils/file";
import UserGuideStep from "./UserGuideStep";

/**
 * User Guide to give a brief overview of each step of the ordering process.
 *
 * @returns The user guide component
 */
export default function UserGuide (): ReactElement {
    const dispatch = useDispatch();
    const order = useSelector((state: RootState) => state.order);

    const [ activeStep, setActiveStep ] = useState<number>(0);

    const steps = [
        {
            header: t("userGuide:steps.designs.title"),
            imgSrc: "/img/user-guide-placeholder.png",
            content: <>
                <p>
                    {t("userGuide:steps.designs.paragraph0")}
                </p>
                <p>
                    <Trans i18nKey="userGuide:steps.designs.paragraph1">
                        If all tests were successful, you can proceed to the next step. Simply click on <i>Continue</i>.
                    </Trans>
                </p>
                <Message
                    header={t("userGuide:steps.designs.note.title")}
                    list={[
                        t("userGuide:steps.designs.note.point0", { supportedFormats: config.files.extensions.join(", ") }),
                        t("userGuide:steps.designs.note.point1", { maxFileSize: formatFileSize(config.files.maxSize) }),
                        t("userGuide:steps.designs.note.point2"),
                        // TODO: Get max dimensions from backend via endpoint
                        t("userGuide:steps.designs.note.point3"),
                        t("userGuide:steps.designs.note.point4", { maxModelsPerOrder: config.order.maxDesignsperOrder }),
                    ]}
                />
            </>,
        },
        {
            header: t("userGuide:steps.variants.title"),
            imgSrc: "/img/user-guide-placeholder.png",
            content: <>
                <p>
                    {t("userGuide:steps.variants.paragraph0")}
                </p>
                <p>
                    <Trans i18nKey="userGuide:steps.variants.paragraph1">
                        Sagen Ihnen die voreingestellten Druckeigenschaften nicht zu, können Sie diese individuell anpassen.
                        Klicken Sie einfach auf <i>Bearbeiten</i> der jeweiligen Variante. Es erscheint ein Dialog zur
                        Anpassung der Parameter. Beispielsweise können Sie das Material und die Farbe ändern. Ebenfalls
                        können Sie auswählen, wie oft Sie eine Variante gedruckt haben möchten. Genauere Informationen
                        erhalten Sie innerhalb des Dialogs.
                    </Trans>
                </p>
                <p>
                    <Trans i18nKey="userGuide:steps.variants.paragraph2">
                        Haben Sie die gewünschten Druckeigenschaften ausgewählt, so klicken Sie auf <i>Übernehmen</i>.
                        Die Speicherung der Änderung kann einige Sekunden dauern. Möchten Sie ein Modell mit
                        unterschiedlichen Druckeigenschaften drucken, so fügen Sie weitere Varianten mittels des
                        <i>Variante hinzufügen</i> Button hinzu.
                    </Trans>
                </p>
                <p>
                    <Trans i18nKey="userGuide:steps.variants.paragraph3">
                        Sind alle Varianten erfolgreich konfiguriert, können Sie zum Checkout fortfahren. Klicken Sie
                        hierfür auf <i>Checkout</i>.
                    </Trans>
                </p>
                <Message
                    header={t("userGuide:steps.variants.note.title")}
                    list={[
                        t("userGuide:steps.variants.note.point0"),
                        t("userGuide:steps.variants.note.point1", { maxVariantsPerDesign: config.designs.maxVariantsPerDesign }),
                        t("userGuide:steps.variants.note.point2", { maxVariantQuantity: config.variants.maxQuantity }),
                    ]}
                />
            </>,
        },
        {
            header: t("userGuide:steps.checkout.title"),
            imgSrc: "/img/user-guide-placeholder.png",
            content: <>
                <p>
                    <Trans i18nKey="userGuide:steps.checkout.paragraph0">
                        Im letzten Schritt können Sie Ihre Bestellung abschließen. Sie werden auf unsere Checkout-Seite
                        weitergeleitet. Sollten Sie Ihre Bestellung noch einmal ändern wollen, können Sie einfach
                        im Browser zurück navigieren. Es sollte ein Dialog erscheinen, der Sie darauf hinweist, dass die
                        Bestellung sich bereits im Checkout befindet. Klicken Sie zum Ändern auf <i>Bestellung öffnen</i>.
                        Laden Sie gegebenenfalls die Seite neu.
                    </Trans>
                </p>
                <Message
                    header={t("userGuide:steps.checkout.note0.title")}
                    list={[
                        t("userGuide:steps.checkout.note0.point0"),
                    ]}
                />
                <Message
                    color="teal"
                    header={t("userGuide:steps.checkout.note1.title")}
                    content={
                        <>
                            <p>
                                <Trans i18nKey="userGuide:steps.checkout.note1.point0">
                                    Gerne können Sie sich direkt an uns wenden. Wir versuchen Ihnen so gut wie möglich zu helfen.
                                    Sie erreichen uns unter <a href="mailto:info@knopfdruck.eu">info@knopfdruck.eu</a>
                                </Trans>
                            </p>
                        </>
                    }
                />
            </>,
        },
    ];

    /**
     *
     */
    function nextStep (): void {
        if (activeStep < steps.length - 1) {
            setActiveStep(activeStep + 1);
        }
    }

    /**
     *
     */
    function previousStep (): void {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    }

    return (
        <Modal
            closeIcon
            centered
            open={order.showUserGuide}
            onClose={(): void => {dispatch(orderStore.toggleUserGuide());}}
        >
            <Modal.Header>{t("userGuide:title")}</Modal.Header>
            <Modal.Content>
                <UserGuideStep
                    header={steps[activeStep].header}
                    content={steps[activeStep].content}
                    imgSrc={steps[activeStep].imgSrc}
                />
            </Modal.Content>
            <Modal.Actions>
                { activeStep > 0 ?
                    <Button
                        color="black"
                        content={t("common:buttons.back")}
                        onClick={previousStep}
                    /> :
                    <></>
                }
                {
                    activeStep < steps.length - 1 ?
                        (
                            <Button
                                color="teal"
                                content={t("common:buttons.continue")}
                                onClick={nextStep}
                            />) :
                        (
                            <Button
                                color="teal"
                                content={t("common:buttons.letsGo")}
                                onClick={(): void => {dispatch(orderStore.toggleUserGuide());}}
                            />)
                }
            </Modal.Actions>
        </Modal>
    );
}
