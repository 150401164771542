import React, {
    ReactElement, useCallback, useRef,
} from "react";
import { isMobile } from "react-device-detect";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Header, Icon, Segment,
} from "semantic-ui-react";

import config from "../../config/config";
import { isServiceError } from "../../errors/types";
import { designStore } from "../../redux/slices/design";
import { orderStore } from "../../redux/slices/order";
import { variantStore } from "../../redux/slices/variant";
import { RootState } from "../../redux/store";
import { DesignService } from "../../service/design";
import { VariantService } from "../../service/variant";
import {
    DesignState, dimensionsAreValid, getDefaultDesignState,
} from "../../states/design";
import { VariantState } from "../../states/variant";
import {
    fileExtensionIsValid, fileSizeIsValid, formatFileSize,
} from "../../utils/file";

/**
 * @returns Component to handle files selected by the user. The files are uploaded to the backend and the
 * redux store is updated accordingly.
 */
export default function DesignUpload (): ReactElement {
    const inputTagRef = useRef<HTMLInputElement>(null);

    const dispatch = useDispatch();

    const order = useSelector((state: RootState) => state.order);
    const designs = useSelector((state: RootState) => state.designs);

    const [ t ] = useTranslation([ "common", "messages" ]);


    /**
     * @returns True if the maximum number of designs has been reached as defined in the configs, false otherwise.
     */
    function maxDesignsReached (): boolean {
        return Object.values(designs).length >= config.order.maxDesignsperOrder;
    }

    /**
     * @returns True if at least one design is currently being uploaded, false otherwise.
     */
    function designsAreUploading (): boolean {
        return Object.values(designs).some((design) => design.isUploading);
    }


    /**
     * @param file - The file to upload
     */
    async function uploadSingleFile (file: File): Promise<void> {
        if (!order.id) {
            return;
        }

        const designPlaceholder = getDefaultDesignState(file);

        if (designs[designPlaceholder.fileAlias] !== undefined) {
            // ? File already exists, therefore skip
            return;
        }

        dispatch(designStore.addDesign(designPlaceholder));

        if (!fileExtensionIsValid(file.name)) {
            dispatch(designStore.setError({ fileAlias: designPlaceholder.fileAlias, error: t("messages:designs.initUpload.invalidFileFormat", { validFormats: config.files.extensions.join(", ") })  }));
            return;
        }

        if (!fileSizeIsValid(file)) {
            dispatch(designStore.setError({ fileAlias: designPlaceholder.fileAlias, error: t("messages:designs.initUpload.fileTooLarge", { maxSize: formatFileSize(config.files.maxSize) }) }));
            return;
        }

        dispatch(designStore.setIsUploading({ fileAlias: designPlaceholder.fileAlias, isUploading: true }));

        const initUploadResponse = await DesignService.initUpload(order.id, file);
        if (isServiceError(initUploadResponse)) {
            dispatch(designStore.setIsUploading({ fileAlias: designPlaceholder.fileAlias, isUploading: false }));
            dispatch(designStore.setError({ fileAlias: designPlaceholder.fileAlias, error: initUploadResponse.message }));
            return;
        }

        const design = initUploadResponse;
        dispatch(designStore.update(design));

        if (!design.id) {
            const errorMessage = t("messages:designs.initUpload.uploadError");
            dispatch(designStore.setError({ fileAlias: design.fileAlias, error: design.error || errorMessage }));
            return;
        }

        const uploadPartsResponse = await DesignService.uploadParts(
            design.id,
            file,
            design.fileAlias,
            (fileAlias: string, progressIncrease: number): void => {
                dispatch(designStore.increaseUploadProgress({ fileAlias: fileAlias, progressIncrease }));
            },
        );

        if (isServiceError(uploadPartsResponse)) {
            dispatch(designStore.setIsUploading({ fileAlias: designPlaceholder.fileAlias, isUploading: false }));
            dispatch(designStore.setError({ fileAlias: design.fileAlias, error: uploadPartsResponse.message }));
            return;
        }
        dispatch(designStore.setIsUploading({ fileAlias: designPlaceholder.fileAlias, isUploading: false }));


        if (!design.dimensions) {
            const dimensionsResponse = await DesignService.getDimensions(design.id);
            if (isServiceError(dimensionsResponse)) {
                dispatch(designStore.setError({ fileAlias: design.fileAlias, error: dimensionsResponse.message }));
                return;
            }

            const dimensions = dimensionsResponse;
            dispatch(designStore.setDimensions({ fileAlias: design.fileAlias, dimensions }));

            if (!dimensions) {
                dispatch(designStore.setError({ fileAlias: design.fileAlias, error: t("messages:designs.dimensions.invalid") }));
                return;
            } else if (!dimensionsAreValid(dimensions)) {
                if (!dimensions.manifold) {
                    dispatch(designStore.setError({ fileAlias: design.fileAlias, error: t("messages:designs.dimensions.nonManifold") }));
                } else {
                    dispatch(designStore.setError({ fileAlias: design.fileAlias, error: t("messages:designs.dimensions.invalid") }));
                }
                return;
            }
        }

        if (!design.publicUrl) {
            const publicUrlResponse = await DesignService.getPublicUrl(design.id);
            if (isServiceError(publicUrlResponse)) {
                dispatch(designStore.setPublicUrl({ fileAlias: design.fileAlias, publicUrl: config.designs.defaultPreviewUrl }));
            } else {
                const publicUrl = publicUrlResponse;
                if (publicUrl) {
                    dispatch(designStore.setPublicUrl({ fileAlias: design.fileAlias, publicUrl }));
                }
            }
        }

        if (!design.compatiblePrintOptions) {
            const compatiblePrintOptionsResponse = await DesignService.getCompatiblePrintOptions(design.id);
            if (isServiceError(compatiblePrintOptionsResponse)) {
                dispatch(designStore.setError({ fileAlias: design.fileAlias, error: compatiblePrintOptionsResponse.message }));
                return;
            }

            const compatiblePrintOptions = compatiblePrintOptionsResponse;
            dispatch(designStore.setCompatiblePrintOptions({ fileAlias: design.fileAlias, compatiblePrintOptions }));
        }
        dispatch(designStore.setIsUploading({ fileAlias: designPlaceholder.fileAlias, isUploading: false }));

        // ? Already adds a new variant upon successful upload
        addVariant(design);
    }


    // TODO: outsource function as it is similar to the one in Variant.tsx
    /**
     * Handler to add a new variant for the given design.
     */
    async function addVariant (design: DesignState): Promise<void> {
        let variant = undefined;
        if (!order.id || !design.id) {
            return;
        }

        dispatch(designStore.setIsSlicing({ fileAlias: design.fileAlias, isSlicing: true }));

        const response = await VariantService.init(order.id, design.id);
        if (isServiceError(response)) {
            dispatch(
                designStore.setWarning({ fileAlias: design.fileAlias, warning: response.message }),
            );
        } else {
            variant = response;
            dispatch(variantStore.addVariant(response));
        }

        dispatch(designStore.setIsSlicing({ fileAlias: design.fileAlias, isSlicing: false }));

        if (variant) {
            updatePrice(variant);
        }
    }

    // TODO: outsource function as it is identical to the one in Variant.tsx
    /**
     * Updates the price of the variant. This function uses the `VariantService` to send a request to the server.
     * The redux store is updated accordingly.
     */
    async function updatePrice (variant: VariantState): Promise<void> {
        const response = await VariantService.getPrice(variant.id);

        if (isServiceError(response)) {
            dispatch(variantStore.setError({ variantId: variant.id, error: response.message }));
            return;
        }

        dispatch(variantStore.setPrice({ variantId: variant.id, pricePerPiece: response }));
    }

    /**
     * Handles the file change event. Once a user has selected files, upload request is sent
     * to the backend. Upon successful upload, the redux store is updated accordingly.
     * If the upload fails, an error message is displayed to the user.
     *
     * @param files - The files accepted
     */

    const onDrop = useCallback((files: File[]) => {
        dispatch(orderStore.clearError());

        if (designsAreUploading()) {
            return;
        }

        if (maxDesignsReached()) {
            dispatch(orderStore.setError(t("messages:designs.maxQuantityReached", { maxQuantity: config.order.maxDesignsperOrder })));
            return;
        }

        if (!order.id) {
            return;
        }

        if (!files) {
            return;
        }

        const newFileCount = files.length + Object.keys(designs).length;

        if (newFileCount > config.order.maxDesignsperOrder) {
            dispatch(orderStore.setError(t("messages:designs.maxQuantityReached", { maxQuantity: config.order.maxDesignsperOrder })));
            return;
        }

        for (const file of files) {
            uploadSingleFile(file);
        }

        // ? Reset input field
        if (inputTagRef.current) {
            inputTagRef.current.value = "";
        }
    }, [ order, designs ]);

    const  { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true, noKeyboard: true });

    function onOpen (): void {
        if (inputTagRef.current) {
            inputTagRef.current.click();
        }
    }

    return (
        <div {...getRootProps()}>
            <Segment
                placeholder
                disabled={designsAreUploading()}
                tertiary={isDragActive}
            >
                <Header
                    as="h4"
                    icon
                >
                    <Icon name="upload"/>
                    <Header.Content>{t("designs:upload.title")}</Header.Content>
                    {
                        !isMobile ?
                            <Header.Subheader>
                                {isDragActive ? t("designs:upload.subtitle.dragActive") : t("designs:upload.subtitle.dragInactive")}
                            </Header.Subheader> :
                            <></>
                    }
                    <Header.Subheader>{config.files.extensions.join(", ")}</Header.Subheader>
                </Header>
                <Button
                    color="teal"
                    disabled={maxDesignsReached() || designsAreUploading()}
                    onClick={onOpen}
                >
                    {t("common:buttons.select")}
                </Button>

                <input
                    {...getInputProps()}
                    type="file"
                    ref={inputTagRef}
                    multiple
                    style={{ display: "none" }}
                    accept={!isMobile ? config.files.extensions.join(",") : undefined}
                />
            </Segment>
        </div>
    );
}
