import axios from "axios";
import { t } from "i18next";

import { Material, Procedure } from "../config/config";
import { OrderErrorHandler } from "../errors/order";
import { ServiceError } from "../errors/types";
import { DesignState, Dimensions } from "../states/design";
import { OrderState } from "../states/order";
import { VariantState } from "../states/variant";


export class OrderService {

    /**
     * Sends a request to initialize a new order and returns the order id if successful
     * If unsuccessful, returns the error code of the failed request
     *
     * @returns Either the order or the error code of the failed request
     */
    static async init (): Promise<{_id: string} | ServiceError> {
        return axios.post(`${ process.env.REACT_APP_BACKEND_HOST }/order/`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return OrderErrorHandler.init(error);
            });
    }

    /**
     * Retrieves the order from the backend, using the order id.
     *
     * @param orderId - The id of the order to retrieve
     */
    static async get (orderId: string): Promise<OrderState | ServiceError> {
        return axios.get(`${ process.env.REACT_APP_BACKEND_HOST }/order/${ orderId }`)
            .then((response) => {
                return {
                    id: response.data._id,
                    step: undefined,
                    designsLoaded: false,
                    variantsLoaded: false,
                    error: null,
                } as OrderState;
            })
            .catch((error) => {
                const serviceError =  OrderErrorHandler.get(error);

                if (serviceError.statusCode === 409) {
                    return {
                        id: orderId,
                        step: undefined,
                        designsLoaded: false,
                        variantsLoaded: false,
                        error: t("messages:order.get.notFound"),
                    } as OrderState;
                }

                return serviceError;
            });
    }

    /**
     * Function to retrieve all designs of an order.
     *
     * @param orderId - The id of the order the designs are associated with
     */
    static async getDesigns (orderId: string): Promise<DesignState[] | ServiceError> {
        return axios.get(`${ process.env.REACT_APP_BACKEND_HOST }/order/${ orderId }/designs`)
            .then((response) => {
                if (response.status === 200) {
                    const designs: DesignState[] = [];

                    response.data.forEach((designObject: { _id: string, fileAlias: string, publicUrl: string, dimensions: Dimensions, hasError: boolean }) => {
                        let dimensions = undefined;
                        if (designObject?.dimensions) {
                            dimensions = {
                                sizeX: designObject.dimensions.sizeX,
                                sizeY: designObject.dimensions.sizeY,
                                sizeZ: designObject.dimensions.sizeZ,
                                manifold: designObject.dimensions.manifold,
                                unit: "mm",
                            } as Dimensions;
                        }

                        designs.push({
                            id: designObject._id,
                            isUploading: false,
                            fileAlias: designObject.fileAlias,
                            publicUrl: designObject.publicUrl || undefined,
                            dimensions,
                            error: designObject.hasError ? t("messages:designs.invalid") : null,
                        } as DesignState);
                    });

                    return designs;
                }

                return [];
            })
            .catch((error) => {
                return OrderErrorHandler.getDesigns(error);
            });
    }

    /**
     * Function to retrieve all variants of an order.
     *
     * @param orderId - The id of the order to retrieve the variants from
     */
    static async getVariants (orderId: string): Promise<VariantState[] | ServiceError> {
        return axios.get(`${ process.env.REACT_APP_BACKEND_HOST }/order/${ orderId }/variants`)
            .then((response) => {
                const variants: VariantState[] = [];

                response.data.forEach((variant_object: {_id: string, designId: string, quantity: number, printOptions: {procedure: Procedure, material: string, colorId: string, infill: number}, hasError: boolean, customerNote: string | undefined}) => {
                    variants.push({
                        id: variant_object._id,
                        designId: variant_object.designId,
                        quantity: variant_object.quantity,
                        printOptions: {
                            procedure: variant_object.printOptions.procedure as Procedure,
                            material: variant_object.printOptions.material as Material,
                            colorId: variant_object.printOptions.colorId,
                            infill: variant_object.printOptions.infill,
                        },
                        pricePerPiece: undefined,
                        editMode: false,
                        isDeleting: false,
                        error: variant_object.hasError ? t("messages:variants.invalid") : null,
                        customerNote: variant_object.customerNote || null,
                    });
                });

                return variants;
            })
            .catch((error) => {
                return OrderErrorHandler.getVariants(error);
            });
    }

    /**
     * This function cancels an order and removes every associated resources (designs and variants).
     *
     * @param orderId - The id of the order to cancel
     */
    static async cancel (orderId: string): Promise<void | ServiceError> {
        return axios.put(`${ process.env.REACT_APP_BACKEND_HOST }/order/${ orderId }/cancel`)
            .then(() => { return; })
            .catch((error) => {
                return OrderErrorHandler.cancel(error);
            });
    }

    /**
     * This function initiates the checkout process for an order.
     *
     * @param orderId - The id of the order to checkout
     */
    static async checkout (orderId: string): Promise<string | ServiceError> {
        return await axios.post(`${ process.env.REACT_APP_BACKEND_HOST }/order/${ orderId }/checkout`)
            .then((response) => { return response.data;})
            .catch((error) => {
                return OrderErrorHandler.checkout(error);
            });
    }

    /**
     * Reopens an order that has already entered the checkout process.
     *
     * @param orderId - The id of the order to reopen
     * @returns - Whether or not the request was successful
     */
    static async reopen (orderId: string): Promise<void | ServiceError> {
        return axios.put(`${ process.env.REACT_APP_BACKEND_HOST }/order/${ orderId }/reopen`)
            .then((response) => {
                if (response.status === 202) {
                    return {
                        statusCode: 202,
                        message: t("messages:order.reopenFailed"),
                    } as ServiceError;
                }
            })
            .catch((error) => {
                return OrderErrorHandler.reopen(error);
            });
    }
}
