import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Container, Header,
} from "semantic-ui-react";

import PageTemplate from "../../templates/PageTemplate";


// TODO: Add a single error page component which shows error code and message dynamically

/**
 * This component is used as an error page for `404` errors.
 *
 * @returns The `404` error page
 */
export default function Error404 () : ReactElement {
    const navigate = useNavigate();
    const [ t ] = useTranslation();

    return (
        <PageTemplate>
            <Container textAlign="center"
                style={{
                    marginTop: "60px",
                    paddingBottom: "60px",
                }}>
                <div>
                    <Header style={{ fontSize: "6em" }}
                        textAlign="center">
                        404
                    </Header>
                    <Header size="huge"
                        style={{ marginBottom: "2em" }}
                        textAlign="center"
                        content={t("common:errorPages.404.title")}
                    />
                    <Button
                        as="a"
                        onClick={(): void => {navigate("/");}}
                        color="teal"
                    >
                        {t("common:buttons.returnHome")}
                    </Button>
                </div>
            </Container>
        </PageTemplate>
    );
}
