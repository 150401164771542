import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    Button, Card,
    Grid, Icon,
    Item,
    Message, Placeholder, Popup, Segment,
} from "semantic-ui-react";

import config from "../../config/config";
import { isServiceError } from "../../errors/types";
import { designStore } from "../../redux/slices/design";
import { variantStore } from "../../redux/slices/variant";
import { RootState } from "../../redux/store";
import { VariantService } from "../../service/variant";
import {
    designIsProcessed,
    designIsReady,
    formatDimensions,
} from "../../states/design";
import { maxVariantsReached } from "../../states/variant";
import DesignPreview from "../designs/Preview";
import Variant from "./Variant";

/**
 * Display a list of variants and the associated design in the Variants page.
 *
 * @param fileAlias - The file alias of the associated design
 * @returns -
 */
export default function VariantList (props: {fileAlias: string}): ReactElement {
    const dispatch = useDispatch();

    const order = useSelector((state: RootState) => state.order);
    const design = useSelector((state: RootState) => state.designs[props.fileAlias]);

    const [ t ] = useTranslation();

    if (!order?.id) {
        return (<></>);
    }

    if (!design) {
        return (<></>);
    }

    if (!design?.id) {
        return (<></>);
    }


    const variants = useSelector((state: RootState) => {
        const variants = state.variants;
        return Object.values(variants).filter((variant) => variant.designId === design.id);
    });


    /**
     * Handler to add a new variant for the given design.
     */
    async function addVariant (): Promise<void> {
        if (!order.id || !design.id) {
            return;
        }

        if (maxVariantsReached(variants)) {
            return;
        }

        dispatch(designStore.setIsSlicing({ fileAlias: design.fileAlias, isSlicing: true }));

        const response = await VariantService.init(order.id, design.id);
        if (isServiceError(response)) {
            dispatch(
                designStore.setWarning({ fileAlias: design.fileAlias, warning: response.message }),
            );
        } else {
            dispatch(variantStore.addVariant(response));
        }

        dispatch(designStore.setIsSlicing({ fileAlias: design.fileAlias, isSlicing: false }));
    }


    return (<>
        <Grid.Row
            columns={2}
            stretched
        >
            <Grid.Column width={6}>
                <Grid container>
                    <Grid.Column width={16}>
                        <Grid.Row>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <DesignPreview fileAlias={design.fileAlias}
                                    size={{ height: 150, width: 150 }}/>
                            </div>
                        </Grid.Row>
                        <Grid.Row>
                            <Item.Group>
                                <Item fluid>
                                    <Item.Content>
                                        <Item.Header style={{ wordWrap: "anywhere", overflowWrap: "anywhere" }}>{design.fileAlias}</Item.Header>

                                        {
                                            !design.dimensions ?
                                                (!design.error ?
                                                    <Placeholder>
                                                        <Placeholder.Line length="medium"/>
                                                    </Placeholder> :
                                                    <br />
                                                )
                                                :
                                                <Item.Description>{formatDimensions(design.dimensions)}</Item.Description>
                                        }

                                        <Item.Meta>
                                            {
                                                variants.reduce((sum) => {
                                                    return sum + 1;
                                                }, 0)
                                            } / { config.designs.maxVariantsPerDesign}
                                            <Popup content={t("variants:notes.maxVariantsPerDesign")}
                                                trigger={
                                                    <Icon name="info circle"
                                                        color="grey"
                                                        style={{ marginLeft: "5px" }}
                                                    />
                                                } />
                                        </Item.Meta>

                                        <Item.Extra>
                                            <Button
                                                secondary
                                                floated="right"
                                                disabled={maxVariantsReached(variants) || !designIsReady(design) || !!design.error || designIsProcessed(design)}
                                                loading={design.isSlicing}
                                                onClick={addVariant}
                                                content={t("common:buttons.addVariant")}
                                            />
                                        </Item.Extra>
                                    </Item.Content>
                                </Item>
                            </Item.Group>
                        </Grid.Row>
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={10}>
                {
                    design.error || design.warning || variants.length === 0 ?
                        (
                            <Segment
                                basic
                                style={{ paddingTop: "0px" }}
                            >
                                <Message
                                    error
                                    icon="exclamation circle"
                                    hidden={!design.error}
                                    content={design.error}
                                    style={{ marginTop: "0px" }}
                                />
                                <Message
                                    warning
                                    icon="warning sign"
                                    hidden={!design.warning}
                                    onDismiss={(): void => {dispatch(designStore.clearWarning(design.fileAlias));}}
                                    content={design.warning}
                                    style={{ marginTop: "0px" }}
                                />
                                <Message
                                    info
                                    icon="info circle"
                                    hidden={variants.length > 0 || !!design.error || !!design.warning}
                                    content={t("variants:notes.noVariants")}
                                    style={{ marginTop: "0px" }}
                                />
                            </Segment>) :
                        <></>
                }

                <Card.Group itemsPerRow={1}>
                    {variants.map((variant) => {
                        if (!variant) {
                            return (<></>);
                        }

                        return (
                            <Variant
                                key={variant.id}
                                fileAlias={design.fileAlias}
                                variantId={variant.id}
                            />
                        );
                    })}
                </Card.Group>
            </Grid.Column>
        </Grid.Row>
    </>);
}
