import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";

import { orderStore } from "../../redux/slices/order";
import { RootState } from "../../redux/store";
import { allDesignsAreValid } from "../../states/design";

export default function DesignPageContinueButton () : ReactElement {
    const dispatch = useDispatch();
    const designs = useSelector((state: RootState) => state.designs);
    const [ t ] = useTranslation([ "common" ]);

    /**
     * @returns Checks whether or not a user should be allowed to continue to the variant page
     */
    function enableContinueButton (): boolean {
        const designArray = Object.values(designs);
        return allDesignsAreValid(designArray);
    }

    /**
     * Handles the continue button click event, when the user changes to the variant page.
     */
    function onContinue (): void {
        dispatch(orderStore.setStep("VARIANTS"));
    }
    return (<Button
        color="teal"
        floated="right"
        disabled={!enableContinueButton()}
        onClick={onContinue}
    >
        {t("buttons.continue")}
    </Button>);
}
